import React from 'react';
import './style.css';
import PanelGroup from 'react-panelgroup'
import Bio from './Bio'

const About = () => {
    return (
      <div >
        <PanelGroup panelWidths={[
          { size: 25, },
          { size: 600},
          { size: 800, },
          { size: 100, minSize: 50, resize: "dynamic" }
        ]}>
          <div>

          </div>
          <div className='bioPanel'>
            <Bio/>
          </div>
          <div>
            <img className='headshot' src={require('../../Images/ProfPic.png')} alt=''/>
          </div>
          <div>

          </div>
        </PanelGroup>
      </div>
    );
};

export default About;