import React from 'react'
import MusicPlayer from '../../components/MusicPlayer/MusicPlayer'
import './Music.css'
import dualityCover from '../../Images/Tonikcover-01.png'

export interface AlbumI {
    title: string
    artist: string,
    tracks: any
}

/**
 * @description 
 * Queries my Local Music Storage to make audio elements to be triggered by Music Players
 * File Stucture
 *  -Music
 *      -collection
 *          -MP3
 *              tracks.mp3...
 *          -OGG
 *              tracks.oog...
 *      -collection...
 */
const getMusic = (): Object => {
    let tracks = (require.context('../../Music/', true, /.*/)).keys();
    let music = { players: [] } as any
    tracks.forEach((track, index) => {        
        let filePathList: string[] = track.slice(2).split('/');
        let mp3URL = require('../../Music/' + track.slice(2))
        let audio: HTMLAudioElement = new Audio(mp3URL)
        let splitTrack: string[] = filePathList[1].split('.');
        let trackTitle: string = splitTrack.slice(1, splitTrack.length - 1).join()
        
        let found = music.players.some((el: any) => el.title === filePathList[0]);
        if (!found) {
            let album: AlbumI = {
                title: filePathList[0],
                artist: "Anthony Reyes",
                tracks: {
                    [splitTrack[0]]: { [splitTrack[splitTrack.length - 1]]: audio, 
                    title: trackTitle }
                }
            };
            (music.players).push(album)
        }
        else {
            // console.log(track, splitTrack[splitTrack.length - 1]);
            let i = music.players.map((player: any, index: number) => {
                if (player.title === filePathList[0]) {
                    return index;
                }
                return -1
            });
            if ((music.players[i]).tracks[splitTrack[0]]) {
                let dex = splitTrack[0];
                let type = splitTrack[splitTrack.length - 1];
                music.players[i].tracks[dex][type] = audio;
                music.players[i].tracks[dex].title = trackTitle ;

            }
            else {
                (music.players[i]).tracks[splitTrack[0]] = { [splitTrack[splitTrack.length - 1]]: audio, title: trackTitle  };
            }
        }
        if (music.players[0].tracks[1]) {
        }
    });
    return music
}

const Music = () => {
    let playlist: any = getMusic();
    return (
        <div className='players-container'>
            <h1>
                Music Portfolio (Under Construction)
            </h1>
            <MusicPlayer playlist={playlist.players[0]} coverArt={dualityCover} />
        </div>

    )
};

export default Music;