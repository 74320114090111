import React, {useState, useEffect} from 'react'
import { BackTrack, ForwardTrack, PauseButton, PlayButton } from '../../Images/Icons/PlayerIcons'
import './MusicPlayer.css'

export interface AlbumI {
	title: string
	artist: string,
	tracks: any
}

interface MusicPlayerI {
	playlist: AlbumI,
    coverArt: string,
}

const MusicPlayer = (props: MusicPlayerI) => {
		const trackList: any = props.playlist.tracks;
    	const [state, setState] = useState({
				currentTrack: trackList[1].mp3,
				songIndex: 1,
				trackStateButton: PlayButton,
				trackState: 'paused',
				currentPlayTime: 0,
				currentDuration: 0,
				SWidth: "0%"
		})
		useEffect(()=>{
			setState((prev) => ({
				...prev,
				currentPlayTime: 0,
				currentDuration: 0,
				SWidth: '0%'
			}));
			state.currentTrack.load()

		}, [state.currentTrack]);
		const toTime = (time: number) => {
			if (isNaN(time)){
				time = 0;
			}
			let mins: number = Math.floor(time / 60)
			let minutes: string = (mins).toString();
			let secs: number = Math.floor(time % 60);
			let seconds: string = (secs).toString();

			if (mins < 10) {
				minutes = '0' + minutes;
			}

			if (secs < 10) {
				seconds = '0' + secs;
			}

			return minutes + ':' + seconds ;
		}
		
		const play = () => {
			setState((prev) => ({ ...prev, trackState: 'playing', trackStateButton: PauseButton }))
			state.currentTrack.play()	
		}

		const pause = () => {
			setState((prev) => ({ ...prev, trackState: 'paused', trackStateButton: PlayButton, currentDuration:0 }))
			state.currentTrack.pause()
		}

		const togglePlay = () => {
			switch (state.trackState){
				case 'paused':
					{
						play();
						break;
					}
				case 'playing':
					{
						pause();
						break;
					}
			}
		}

		const setDurationToZero = () =>
		{
			setState((prev) => ({...prev, currentDuration: 0}))
		}
		
		const IconButton = (icon: JSX.Element, onClick: any, style: Object = {}) => {
			return (
				<button onClick={onClick} style={style}>
				{icon}
			</button>);
		}

		const calcWidth = () => {
			let width: number = (state.currentPlayTime / state.currentDuration)*100
			let w: string = width.toString();
			if (width === Infinity){
				w = "0";
			}
			if (isNaN(width)){
				w = "0";
			}
			return w + '%';
		}
    const TopPart = () => {		
			state.currentTrack.ontimeupdate = () => {
				setState((prev) => 
				({ 
					...prev,  
					currentPlayTime: state.currentTrack.currentTime,
					currentDuration: state.currentTrack.duration,
					SWidth: calcWidth()
				}))
				
			}
			state.currentTrack.addEventListener('loadeddata', () => {
				setState((prev) =>
					({
						...prev,
						currentDuration: state.currentTrack.duration,
						SWidth: calcWidth()
					}))
				
				// The duration variable now holds the duration (in seconds) of the audio clip 
			})
			const SliderBar = (
				<div className='kkSlider'>
					<div className='kkCrusin' style={{ width: state.SWidth }} />
				</div>
			);
			
			return (
            <div className='player'>
                <img src={props.coverArt} alt='' style={{width: '20%', marginRight: '25px'}}/>
								<div 
									className='TitleAndControls'
                	style={{
                    display: 'flex', 
                    flexDirection: "column", 
                    justifyContent: "space-between", 
										boxSizing: "border-box"}}>
									<div className='album-actions-title' style={{
												display: "flex", fontFamily: "playfair display"}}>
										{IconButton(state.trackStateButton, 
																(() => { togglePlay() }),
																{marginRight: '25px'})}
										<div className='title' style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
											<p>
												{props.playlist.artist}
											</p>
											<p style={{fontSize:'2em'}}>
												{trackList[state.songIndex].title}
											</p>
											<p>
												{props.playlist.title}
											</p>
										</div>
									</div>
									<div className='Controls'>
											{IconButton(BackTrack, (() => { pause(); state.currentTrack.load(); setDurationToZero();}))}
											{IconButton(ForwardTrack, (()=>{}))}
											{SliderBar}
											{toTime(state.currentPlayTime)}
											/
											{toTime(state.currentDuration)}
									</div>
                </div>
            </div>
				)};
				
		const TrackList = () => {
			const triggerTrack = (trackNum: number) => {
				pause();
				state.currentTrack.load()
				setState((prev) => ({
					...prev,
					currentTrack: trackList[trackNum].mp3,
					songIndex: trackNum,
					currentPlayTime: 0,
					currentDuration: 0,
					SWidth: '0%'
				}));
				state.currentTrack.load()
			}
			const makeTrackDiv = (trackNum: any) => {
				return (
					<div className='track-button-container' onClick={() => { triggerTrack(trackNum) }}>
						<div className='track-li-elements'>
							<span style={{paddingRight:'1em'}}> {trackNum}.</span>
							<span> {trackList[trackNum].title}  </span>
						</div>
					</div>
				)
			}
			return (
				<div className='listDiv'>
					<ol className='ordered-list-object'>
						{Object.keys(trackList).map((trackNum: any) => {						
							return (
								<li key={trackNum}>
									{makeTrackDiv(trackNum)}
								</li>
							)}
						)}	
					</ol>
				</div>
				)
		}

    return (
        <div className='MusicPlayer-Wrapper'>
          {TopPart()}
					{TrackList()}
        </div>

    )
};

export default MusicPlayer;