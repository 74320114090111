import React from 'react'
import SiteButtons from '../SiteButtons/SiteButtons'
import './header.css'

const SiteHeader = () => {
    return (
      <div className="Site-Header">
        <SiteButtons text='Home' navigateTo='/'/>
        <SiteButtons text='About' navigateTo='about'/>
        <SiteButtons text='Music' navigateTo='music'/>
    	</div>
    )
};

export default SiteHeader;