import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Music from './Views/Music/Music'
import Home from './Views/Home/Home'
import About from './Views/About/About'
import NotFound from './Views/notfound/index'
import SiteHeader from './components/SiteHeader/header'
import { useRoutes } from 'hookrouter';

const routes = {
  "/": () => <Home />,
  "/about": () => <About/>,
  "/music": () => <Music/>,
  "/*": () => <NotFound/>,

};

const Site = () => {
  const routeResult = useRoutes(routes);

  return (
    <div>
      <SiteHeader/>
      {routeResult}
    </div>
  );

}

ReactDOM.render(
  <Site/>,
  document.getElementById('root')
);