import React from 'react';
export const BackTrack = (
<svg 
xmlns="http://www.w3.org/2000/svg" 
width="22" 
height="13" 
viewBox="0 0 22 13">
    <g transform="rotate(180 11 6.5)">
        <path d="M0.728,0.171A0.485,0.485,0,0,0,0,.6V12.4a0.485,0.485,0,0,0,.728.426l9.113-5.9a0.5,0.5,0,0,0,0-.852Zm10.083,0A0.485,0.485,0,0,0,10.083.6V12.4a0.485,0.485,0,0,0,.728.426l9.113-5.9a0.5,0.5,0,0,0,0-.852ZM21.083,0.1a0.915,0.915,0,0,0-.917.913v10.96a0.917,0.917,0,0,0,1.833,0V1.018A0.915,0.915,0,0,0,21.083.1Z"/>
    </g>
</svg>);