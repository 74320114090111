import React from 'react';
import './style.css'

const Home = () => {

    return (
      <div className="PannelContainer">
        <img className='ConcertPic' src={require('../../Images/DSC01840.jpg')} alt='' />
        <div className="outerpannel" />
        <div className="Name-Div">
          <div className="title">
            <h1 className="Name-H1"> Anthony Reyes </h1>
            <h3> Software Engineer and Musician </h3>
          </div>
          <div className='homepage-links'>
            <a className='GitHub-Link' href='https://github.com/AnthonyReyes-ComputerScience'>
              <h4> GitHub </h4>
            </a>
            <a className='Duality-Link' href='https://artist.landr.com/music/800739977020'>
              <h4> Debut Album: Duality </h4>
            </a>
          </div>
        </div>
      </div>
    );
}

export default Home
