import React from 'react'

const Bio = () => {
  return (
		<span>
			<h1>
				Biography:
			</h1>
			<p>
				Anthony Reyes is a 21-year-old Cuban-American Software Engineer, and multifaceted Musician. Reyes was born in Miami, Florida in and
				moved between Miami, Florida and L.A., California for his early childhood,
				being exposed to grassroots Latino music and Hip-Hop. 
			</p>

			<p>
				
				Anthony Reyes attended New World School of the Arts (NWSA) High School, as a 
				flutist under the instruction of Suzan DeGooyer.
				Anthony had received his first of 3 Music Theory awards and had been able
				to seize opportunities at NWSA like: performing in orchestra pits for musicals 
				such as Evita, Cabaret, Once On This Island and Fiddler On the Roof, 
				attending workshops with the New World Symphony with visiting conductor 
				Micheal Tilson Thomas, attending and performing at the Orlando Flute Festival, 
				performing an original Sammy Nestico piece <em>Swingphonic</em> featuring Dr. Ed Calle 
				with the NWSA 
				orchestra, hosting a senior recital, and becoming a Young Talent Big Dreams
				Finalist.
			</p>

			<p>
				During Reyes' final year at NWSA in 2016, he became a POSSE scholar
				for Hamilton College. Reaching Hamilton's campus, Reyes found 
				interest in Computer Science and delved deep into the world of 
				music production and computer music. Reyes 
				quickly familiarized himself with Ableton Live 9 and Max MSP during 
				his freshman year to begin preparing the release of an electronic
				music album. Along with Computer Science and Music Production, 
				Anthony was under the mentorship of Micheal "the Doctah" Woods for 
				Jazz Composition and Theory, Rick Montalbano for Jazz Piano, Kay
				Paulsen for Voice, Jimmy John for Drumming, and Ryan Carter for
				Music Technology. In the summer of 2018, Reyes released his debut
				album Duality after being awarded an Emerson grant to do so.
				Being the Music director of his a Cappella group, The Hamilton
				College Buffers, He led the group to perform at Radio City 
				Music Hall to open for the Rockettes that same year and the following year to come.
			</p>
			
			<p>
				Along with his advancements in Music, Anthony focused on developing principals of 
				computer science, and music computation in various languages such as C++, Python, 
				C#, JavaScript, and Java, along with visual music programming language Max MSP. 
				As Anthony's 
				Senior Project he developed an interactive VR simulation of conducting Hamilton 
				College's Orchestra with a team of 4 utilizing Oculus as the VR engine and Unity as
				a game engine as the simulation environtment. 
			</p>
		</span>
    )
}

export default Bio;