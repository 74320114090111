import React from 'react'
import { navigate } from 'hookrouter'

interface SiteButtonsI {
    navigateTo: string,
    text: string
}

const SiteButtons = (props: SiteButtonsI) => {
    return (
        <button className='header-btn' onClick={()=>{navigate(props.navigateTo)}}>
            {props.text}
        </button>
    )
}

export default SiteButtons